import React from 'react';
import { Link, navigate, graphql } from 'gatsby';
import styled from 'styled-components';
import { between, transparentize } from 'polished';
import { FaLongArrowAltLeft, FaLongArrowAltRight, FaExclamationCircle, FaInfoCircle, FaSpinner } from 'react-icons/fa';

import Auth from '../utils/auth.js';
import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import SEO from '../components/seo';
import Container from '../components/container';
import PostForm from '../components/post-form';
import Alert from '../components/alert';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '470px')};
`;

const StyledContainer = styled(Container)`
	max-width: ${props => props.theme.breakpoints.sm};

	@media (min-width: ${props => props.theme.breakpoints.md}) {
		background: ${props => props.theme.colors.white};
		margin-top: ${between('1px', '-100px')};
		padding: 24px;
		position: relative;
		z-index: 100;
		box-shadow: 0 1px 50px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)},
			0 2px 15px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)};
	}
`;

const BackLink = styled(Link)`
	margin-bottom: 40px;
	display: inline-block;

	@media (min-width: ${props => props.theme.breakpoints.md}) {
		position: absolute;
		margin-top: -70px;
		margin-left: -24px;
		margin-bottom: 0;
	}
`;

const StyledAlert = styled(Alert)`
	a,
	.link {
		color: ${props => props.theme.colors.golden_500};
		cursor: pointer;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
`;

class EditOfferPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isError: false,
			categories: [],
			towns: [],
			isUserLogged: false,
		};

		props.data.allMysqlCategory.edges.forEach(({ node }) => {
			const obj = { value: node.slug, label: node.label_nominative };
			this.state.categories.push(obj);
		});

		props.data.allMysqlTown.edges.forEach(({ node }) => {
			const obj = { value: node.slug, label: node.label_nominative };
			this.state.towns.push(obj);
		});

		this.logIn = this.logIn.bind(this);
		this.handleLogInKeyPress = this.handleLogInKeyPress.bind(this);
	};

	componentDidMount() {
		const auth = new Auth();
		if (!auth.isAuthenticated()) return;

		this.setState({ isUserLogged: true });
		this.getUserPost();
	}

	logIn() {
		const auth = new Auth();
		auth.login('/profil/');
	}

	handleLogInKeyPress(event) {
		if (event.charCode === 13 || event.charCode === 32) this.logIn();
	}

	getUserPost() {
		if (typeof window !== 'undefined' && window && window.localStorage && window.localStorage.getItem('userAccessToken')) {
			const accessToken = window.localStorage.getItem('userAccessToken');

			if (!accessToken) {
				this.setState({ isUserLogged: false });
				return;
			}

			const urlParams = new URLSearchParams(window.location.search);
			if (!urlParams.has('id')) return navigate('/profil/');

			fetch(`/.netlify/functions/get-post?id=${urlParams.get('id')}`, { headers: { Authorization: `Bearer ${accessToken}` } })
				.then(res => res.json())
				.then(response => {
					this.setState({ isLoading: false, post: response });
				})
				.catch(error => {
					this.setState({ isLoading: false, isError: true });
					// @todo - save error
					console.error('[API]', error);
				});
		}
	}

	render() {
		return (
			<Page>
				<SEO title="Edytuj ofertę" description="Zarządzaj swoimi ofertami na portalu Weselnicy.pl" />
				<StyledHeaderSection hero="/hero-profile.jpg">
					<HeaderFront>
						<Navigation />
						<Container>
							<HeaderHeading>Edytuj ofertę</HeaderHeading>

							{this.state.isUserLogged && this.state.post &&
								<HeaderDescription>{this.state.post.title}</HeaderDescription>
							}

							{!this.state.isUserLogged &&
								<HeaderDescription>
									<button type="button" onClick={this.logIn}>Zaloguj się <FaLongArrowAltRight /></button>
								</HeaderDescription>
							}
						</Container>
					</HeaderFront>
				</StyledHeaderSection>

				<StyledContainer>
					{this.state.isUserLogged &&
						<>
							<BackLink to="/profil/"><FaLongArrowAltLeft /> Powrót</BackLink>

							{this.state.isLoading &&
								<Alert>
									<FaSpinner className="icon rotate" /> Ładowanie...
								</Alert>
							}

							{!this.state.isLoading &&
								<>
									{this.state.isError &&
										<Alert>
											<FaExclamationCircle className="icon" /> Ups... nie udało się pobrać informacji o ofercie, spróbuj ponownie za chwilę
										</Alert>
									}

									{!this.state.isError &&
										<PostForm categories={this.state.categories} towns={this.state.towns} post={this.state.post} />
									}
								</>
							}
						</>
					}

					{!this.state.isUserLogged &&
						<StyledAlert>
							<FaInfoCircle className="icon" />
							<span className="link" role="button" tabIndex="0" onClick={this.logIn} onKeyPress={this.handleLogInKeyPress}>Zaloguj się</span>, aby edytować ofertę
						</StyledAlert>
					}
				</StyledContainer>
			</Page>
		);
	};
};

export default EditOfferPage;

export const query = graphql`
	query EditOfferPage {
		allMysqlTown {
			edges {
				node {
					slug
					label_nominative
				}
			}
		}

		allMysqlCategory {
			edges {
				node {
					slug
					label_nominative
				}
			}
		}
	}
`;
