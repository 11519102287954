import React from 'react';
import { Beforeunload } from 'react-beforeunload';
import { navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import { between } from 'polished';
import { FaLongArrowAltRight, FaQuestionCircle, FaSpinner, FaCloudUploadAlt, FaTrash, FaCheckCircle, FaBold, FaItalic, FaUnderline, FaHeading, FaListUl, FaExclamationCircle } from 'react-icons/fa';
import Select from 'react-select';
import sanitizeHtml from 'sanitize-html';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import Alert from './alert';

import 'draft-js/dist/Draft.css';

const Wrapper = styled.div`
	padding-top: 20px;
`;

const StyledForm = styled.form`
	.field {
		& + .field {
			margin-top: 30px;
		}

		&.bottom button {
			margin-top: 20px;
		}

		label {
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 3px;

			&.required::after {
				content: '*';
				color: ${props => props.theme.colors.golden_500};
				font-size: 12px;
				margin-left: 3px;
			}
		}

		input[type='text'] {
			width: 100%;
		}

		.short {
			width: 100%;
			max-width: 300px;
			display: block;

			& + input {
				margin-top: 13px;

				& + label {
					font-weight: 400;
				}
			}
		}

		input:not([type='checkbox']),
		.textarea {
			padding: 10px 10px;
			border: solid 1px hsl(0, 0%, 88%);

			&:hover {
				border: solid 1px hsl(0, 0%, 70%);
			}

			&:focus {
				outline: solid 2px hsl(41, 8%, 61%);
			}
		}

		.textarea {
			width: 100%;
			min-height: 150px;
			margin-top: 5px;
			line-height: ${between('22px', '24px')};

			* + * {
				margin-top: 23px;
			}

			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: 'Playfair Display', serif;
				font-weight: 700;

				& + * {
					margin-bottom: 0;
					margin-top: -10px;
				}
			}

			ul,
			ol {
				margin-bottom: 35px;

				li {
					margin-top: 10px;
				}
			}

			&:focus {
				border: none;
			}
		}

		.small {
			font-size: 12px;
			display: block;
			margin-top: 5px;
			line-height: 18px;
		}

		@media (min-width: ${props => props.theme.breakpoints.xs}) {
			.short {
				display: inline-block;

				& + input {
					margin-left: 20px;
				}
			}
		}

		@media (min-width: ${props => props.theme.breakpoints.lg}) {
			.textarea {
				min-height: 320px;
			}
		}
	}
`;

const spinAnimation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
`;

const AddOfferButton = styled.button`
	background: ${props => props.theme.colors.golden_100};
	width: 100%;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding-top: ${between('14px', '22px')};
	padding-bottom: ${between('13px', '21px')};
	border: solid 1px ${props => props.theme.colors.golden_400};
	color: ${props => props.theme.colors.warm_grey_1000};
	border-radius: 1px;
	position: relative;
	cursor: pointer;
	transition: all 0.3s;

	svg {
		width: 16px;
		margin-left: 5px;
		margin-bottom: -3px;
		fill: ${props => props.theme.colors.warm_grey_1000};
		transition: all 0.3s;
	}

	.rotate {
		animation: ${spinAnimation} 2s infinite linear;
	}

	&:hover,
	&:focus {
		background: ${props => props.theme.colors.golden_400};
		transition-delay: 0.3s;
		text-decoration: none;
		outline: none;
		letter-spacing: 3px;

		svg {
			fill: ${props => props.theme.colors.warm_grey_1000};
			transition-delay: 0.3s;
		}
	}
`;

const Hint = styled(FaQuestionCircle)`
	color: ${props => props.theme.colors.warm_grey_300};
	cursor: help;
`;

const Error = styled.span`
	color: #f00;
	font-size: 14px;
	font-weight: 600;
	display: block;
	margin-top: 5px;
	padding-left: 1px;
`;

const Gallery = styled.div`
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fit, 150px);
	grid-gap: 26px;

	.item {
		width: 150px;
		position: relative;

		&.add {
			cursor: pointer;
			border: dashed 2px ${props => props.theme.colors.warm_grey_200};
			border-radius: ${props => props.theme.border_radius};
			transition: all 0.3s;

			.wrapper {
				margin-top: 15px;
				text-align: center;

				.icon {
					font-size: 26px;

					&.rotate {
						animation: ${spinAnimation} 2s infinite linear;
					}
				}
			}

			&:hover,
			&:focus {
				background: ${props => props.theme.colors.warm_grey_100};
				border-color: ${props => props.theme.colors.warm_grey_300};
				outline: none;

				p {
					text-decoration: underline;
				}
			}
		}

		.move,
		.delete {
			background: ${props => props.theme.colors.warm_grey_500};
			color: ${props => props.theme.colors.white};
			position: absolute;
			margin: -10px;
			padding: 4px 5px;
			border-radius: 50%;
			font-size: 12px;
			cursor: pointer;
			transition: all 0.3s;

			&:hover {
				background: ${props => props.theme.colors.warm_grey_700};
			}
		}

		.delete {
			right: 0;

			&:hover {
				background: red;
			}
		}

		img {
			width: 150px;
			height: 150px;
			object-fit: cover;
			user-select: none;
		}
	}
`;

const EditButtons = styled.div`
	margin-left: -3px;
	margin-bottom: 7px;

	button {
		background: ${props => props.theme.colors.warm_grey_100};
		color: ${props => props.theme.colors.warm_grey_700};
		padding: 8px 10px 6px 10px;
		border: solid 1px ${props => props.theme.colors.warm_grey_200};
		font-weight: 400;
		font-size: 15px;
		margin: 3px;
		cursor: pointer;
		transition: all 0.3s;

		span {
			font-size: 17px;
			vertical-align: bottom;
			font-weight: 600;
			margin-left: 1px;
		}

		&:hover {
			background: ${props => props.theme.colors.warm_grey_200};
		}
	}
`;

const StyledAlert = styled(Alert)`
	margin-bottom: 46px;
`;

const styledSelect = { menu: styles => ({ ...styles, zIndex: 30 }) };

class PostForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isSubmitButtonBlocked: false,
			isUploadInProgress: false,
			isSuccess: false,
			isAPIError: false,
			categories: this.props.categories,
			towns: this.props.towns,
			selectedCategory: null,
			selectedTown: null,
			isTownsDropdownVisible: false,
			showRadiusOptions: false,
			radiusOptions: [
				{ value: -1, label: 'Cała Polska' },
				{ value: 150, label: '+150 km' },
				{ value: 100, label: '+100 km' },
				{ value: 50, label: '+50 km' },
				{ value: 20, label: '+20 km' },
				{ value: 0, label: 'Tylko moje miasto' },
			],
			selectedRadiusOption: -1,
			title: '',
			price: '',
			price_from: false,
			name: '',
			phone: '',
			allow_whatsapp: false,
			messenger: '',
			photosValid: [],
			photosAll: [],
			description: EditorState.createEmpty(),
			formErrors: {
				category: '',
				title: '',
				town: '',
				price: '',
				phone: '',
				photos: '',
				description: '',
			},
		};

		if (this.props.post) {
			this.state.title = this.props.post.title;
			this.state.price = this.props.post.price.toString();
			this.state.price_from = !!this.props.post.price_from;
			this.state.name = this.props.post.name;
			this.state.phone = this.props.post.phone;
			this.state.allow_whatsapp = !!this.props.post.whatsapp.length;
			this.state.messenger = this.props.post.messenger;
			this.props.post.photos.split(', ').forEach(p => {
				const photoElement = {
					path: p,
					deleteToken: '',
				};
				this.state.photosValid.push(photoElement);
				this.state.photosAll.push(photoElement);
			});
			const contentState = stateFromHTML(this.props.post.description);
			this.state.description = EditorState.createWithContent(contentState);

			this.state.selectedCategory = this.state.categories.find(c => c.value === this.props.post.category_slug);
			this.state.selectedTown = this.state.towns.find(t => t.value === this.props.post.town_slug);
			this.state.selectedRadiusOption = this.state.radiusOptions.find(t => t.value === this.props.post.radius);
		}

		this.fileSelectorRef = React.createRef();
		this.formTopRef = React.createRef();

		this.hideTownsDropdown = this.hideTownsDropdown.bind(this);
		this.handleTownChange = this.handleTownChange.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
		this.handleTownInputChange = this.handleTownInputChange.bind(this);
		this.handleRadiusOptionChange = this.handleRadiusOptionChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
		this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
		this.toggleBlockStyle = this.toggleBlockStyle.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.focusEditor = this.focusEditor.bind(this);
		this.sanitizeDescription = this.sanitizeDescription.bind(this);
		this.showFileUpload = this.showFileUpload.bind(this);
		this.handleSelectedFiles = this.handleSelectedFiles.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.validate = this.validate.bind(this);
		this.submitPost = this.submitPost.bind(this);
	};

	componentDidMount() {
		if (typeof window !== 'undefined' && window && window.localStorage) {
			let selectedTown = window.localStorage.getItem('lastUserTown');
			if (selectedTown) {
				try {
					selectedTown = JSON.parse(selectedTown);
					this.setState({ selectedTown });
				} catch (e) { }
			}

			let selectedCategory = window.localStorage.getItem('lastUserCategory');
			if (selectedCategory) {
				try {
					selectedCategory = JSON.parse(selectedCategory);
					this.handleCategoryChange(selectedCategory);
				} catch (e) { }
			}
		}
	}

	hideTownsDropdown() {
		this.setState({ isTownsDropdownVisible: false });
	};

	handleTownChange(selectedTown) {
		this.setState({ selectedTown });
		if (typeof window !== 'undefined' && window && window.localStorage) window.localStorage.setItem('lastUserTown', JSON.stringify(selectedTown));
		this.hideTownsDropdown();
	};

	handleCategoryChange(selectedCategory) {
		this.setState({ selectedCategory });
		this.setState({ showRadiusOptions: selectedCategory.value !== this.state.categories[0].value });
		if (typeof window !== 'undefined' && window && window.localStorage) window.localStorage.setItem('lastUserCategory', JSON.stringify(selectedCategory));
	};

	handleTownInputChange(query, { action }) {
		if (action === 'input-change') this.setState({ isTownsDropdownVisible: query.length > 1 });
	};

	handleRadiusOptionChange(selectedRadiusOption) {
		this.setState({ selectedRadiusOption });
	};

	handleInputChange(event) {
		const { id, value } = event.target;
		this.setState({ [id]: value });
	};

	handleDescriptionChange(data) {
		this.setState({ description: data });
	}

	toggleInlineStyle(event) {
		event.preventDefault();
		const style = event.currentTarget.getAttribute('data-style');
		this.setState({
			description: RichUtils.toggleInlineStyle(this.state.description, style)
		});
	}

	toggleBlockStyle(event) {
		event.preventDefault();
		const style = event.currentTarget.getAttribute('data-style');
		this.setState({
			description: RichUtils.toggleBlockType(this.state.description, style)
		});
	}

	handleCheckboxChange(event) {
		this.setState({ [event.target.id]: event.target.checked });
	};

	focusEditor() {
		this.editor.focus();
	};

	sanitizeDescription(html) {
		return sanitizeHtml(html, {
			allowedTags: ['b', 'strong', 'br', 'i', 'u', 'em', 'strong', 'p', 'a', 'ul', 'ol', 'li', 'h2', 'h3', 'h4', 'h5', 'h6'],
		});
	};

	showFileUpload() {
		this.fileSelectorRef.current.click();
	};

	handleSelectedFiles(event) {
		for (const file of event.target.files) {
			if (this.state.photosValid.length >= 15) break;

			if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
				const photoElement = { error: 'Niepoprawy format, tylko pliki *.jpg lub *.png' };

				this.setState(prevState => ({
					photosAll: [...prevState.photosAll, photoElement],
				}));

				break;
			}

			if (file.size > 10485760) { // 10 MB
				const photoElement = { error: 'Zdjęcie jest zbyt duże, max. 10MB' };

				this.setState(prevState => ({
					photosAll: [...prevState.photosAll, photoElement],
				}));

				break;
			}

			let isValid = true;

			const imgReader = new Image();
			imgReader.onerror = () => {
				isValid = false;
				const photoElement = { error: 'Niepoprawy format, tylko pliki *.jpg lub *.png' };

				this.setState(prevState => ({
					photosAll: [...prevState.photosAll, photoElement],
				}));
			};

			imgReader.onload = () => {
				const minWidth = 800;
				const minHeight = 600;
				if (isValid && (imgReader.naturalWidth < minWidth || imgReader.naturalHeight < minHeight)) {
					isValid = false;

					const photoElement = { error: `Zdjęcie jest za małe, min. ${minWidth}x${minHeight}px` };

					this.setState(prevState => ({
						photosAll: [...prevState.photosAll, photoElement],
					}));
				}

				if (isValid) {
					this.setState({ isUploadInProgress: true });

					const fd = new FormData();
					fd.append('upload_preset', 'website');
					fd.append('file', file);

					fetch('https://api.cloudinary.com/v1_1/weselnicy/image/upload', {
						method: 'POST',
						body: fd,
					})
						.then(res => {
							if (!res.ok) {
								// @todo - save error
								throw res.statusText;
							}
							return res.json();
						})
						.then(response => {
							const photoElement = {
								path: response.public_id,
								deleteToken: response.delete_token,
							};

							this.setState(prevState => ({
								photosAll: [...prevState.photosAll, photoElement],
								photosValid: [...prevState.photosValid, photoElement],
							}));
						})
						.catch(error => {
							// @todo - save error
							console.error('[Cloudinary]', error);
							const photoElement = { error: 'Nie udało się przesłać zdjęcia' };

							this.setState(prevState => ({
								photosAll: [...prevState.photosAll, photoElement],
							}));
						})
						.finally(() => this.setState({ isUploadInProgress: false }));
				}
			};

			imgReader.src = URL.createObjectURL(file);
		}
	};

	deleteFile(i) {
		const { path, deleteToken } = this.state.photosAll[i];

		const copyPhotosAll = [...this.state.photosAll];
		copyPhotosAll.splice(i, 1);
		this.setState({
			photosAll: [...copyPhotosAll],
		});

		if (path) {
			const photosValidUpdated = this.state.photosValid.filter(p => p.path !== path);
			this.setState({
				photosValid: [...photosValidUpdated],
			});
		}

		if (deleteToken) {
			(async () => {
				const req = await fetch('https://api.cloudinary.com/v1_1/weselnicy/delete_by_token', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ token: deleteToken, invalidate: true }),
				});
				await req.json();
			})();
		}
	};

	validate(form) {
		const minTitleLength = 10;
		const maxTitleLength = 100;
		const minDescriptionLength = 50;

		const errorMessages = {
			category: {
				required: 'Wybierz kategorię',
			},
			title: {
				too_short: `Tytuł jest zbyt krótki, min. ${minTitleLength} znaków`,
				too_long: `Tytuł jest zbyt długi, max. ${maxTitleLength} znaków`,
				required: 'Wpisz tytuł oferty',
			},
			town: {
				required: 'Wybierz miasto',
			},
			price: {
				incorrect: 'Cena jest niepoprawna',
				required: 'Podaj cenę oferty',
			},
			name: {
				required: 'Podaj swoje imię',
			},
			phone: {
				incorrect: 'Numer telefonu jest niepoprawny',
				required: 'Numer telefonu jest wymagany',
			},
			photos: {
				required: 'Dodaj co najmniej jedno zdjęcie',
			},
			description: {
				too_short: `Opis jest zbyt krótki, min. ${minDescriptionLength} znaków`,
				required: 'Opisz swoją ofertę',
			},
		};

		let isValidated = true;
		for (const field in errorMessages) {
			this.setState(state => (this.state.formErrors[field] = '', state));

			for (const validation in errorMessages[field]) {
				if (
					(validation === 'required' && (
						(field === 'photos' && this.state.photosValid.length === 0) ||
						(field === 'price' && form[field] === 0) ||
						form[field] === ''
					)) ||
					(validation === 'incorrect' && field === 'price' && isNaN(form[field])) ||
					(validation === 'incorrect' && field === 'phone' && form[field] && (
						(form[field].replace(/\D/g, '').length < 9) ||
						(form[field].replace(/\D/g, '').length > 13)
					)) ||
					(validation === 'too_long' && field === 'title' && form[field].length > maxTitleLength) ||
					(validation === 'too_short' && field === 'title' && form[field].length < minTitleLength) ||
					(validation === 'too_short' && field === 'description' && form[field].length < minDescriptionLength)
				) {
					isValidated = false;
					this.setState(state => (state.formErrors[field] = errorMessages[field][validation], state));
				}
			}
		}

		return isValidated;
	};

	submitPost(event) {
		event.preventDefault();
		this.setState({ isSuccess: false, isAPIError: false });

		if (!this.state.isSubmitButtonBlocked) {
			this.setState({ isSubmitButtonBlocked: true });

			const form = {
				category: this.state.selectedCategory ? this.state.selectedCategory.value.trim() : '',
				title: this.state.title.trim(),
				town: this.state.selectedTown ? this.state.selectedTown.value.trim() : '',
				price: Math.round(this.state.price.trim().replace(',', '.')),
				price_from: this.state.price_from,
				name: this.state.name.trim(),
				phone: this.state.phone.trim(),
				photos: this.state.photosValid.map(p => p.path),
				description: this.sanitizeDescription(stateToHTML(this.state.description.getCurrentContent())),
			};

			if (this.state.phone) {
				form.allow_whatsapp = this.state.allow_whatsapp;
			}

			form.radius = this.state.showRadiusOptions ? this.state.selectedRadiusOption.value : 0;
			if (this.state.messenger) form.messenger = this.state.messenger.trim();

			if (!this.validate(form)) {
				this.formTopRef.current.scrollIntoView({ behavior: 'smooth' });
				this.setState({ isSubmitButtonBlocked: false });
			} else {
				const isNewPost = !this.props.post;
				const accessToken = window.localStorage.getItem('userAccessToken');

				let url = '/.netlify/functions/';
				url += isNewPost ? 'create-post' : `update-post?id=${this.props.post.external_uid}`;

				fetch(url, {
					method: isNewPost ? 'POST' : 'PUT',
					headers: { Authorization: `Bearer ${accessToken}` },
					body: JSON.stringify(form),
				})
					.then(res => {
						if (!res.ok) {
							// @todo - save error
							throw res.statusText;
						}
						return res.json();
					})
					.then(() => {
						if (isNewPost) {
							window.localStorage.setItem('newPostAdded', 'true');
							navigate('/dodaj-oferte/dziekujemy/');
						} else {
							this.setState({ isSuccess: true, isSubmitButtonBlocked: false });
							this.formTopRef.current.scrollIntoView({ behavior: 'smooth' });
						}
					})

					.catch(() => {
						this.setState({ isAPIError: true, isSubmitButtonBlocked: false });
						this.formTopRef.current.scrollIntoView({ behavior: 'smooth' });
					});
			}
		}
	};

	render() {
		return (
			<Wrapper ref={this.formTopRef}>
				<Beforeunload onBeforeunload={() => "You'll lose your data!"} />

				{this.state.isSuccess &&
					<StyledAlert className="success">
						<FaCheckCircle className="icon" /> Zmiany zostały zapisane i zostaną wkrótce opublikowane
					</StyledAlert>
				}

				{this.state.isAPIError &&
					<StyledAlert className="error">
						<FaExclamationCircle className="icon" /> Ups... coś poszło nie tak, spróbuj ponownie za chwilę
					</StyledAlert>
				}

				<StyledForm onSubmit={this.submitPost}>
					{!this.props.post &&
						<div className="field">
							<label htmlFor="categories" className="required">Kategoria</label>
							<Select id="categories" options={this.state.categories} value={this.state.selectedCategory} className="select" placeholder="Wybierz kategorię..." onChange={this.handleCategoryChange} isSearchable={false} styles={styledSelect} theme={theme => ({
								...theme,
								borderRadius: 0,
								zIndex: 30,
								colors: {
									...theme.colors,
									primary25: 'hsl(43, 13%, 90%)',
									primary50: 'hsl(43, 13%, 90%)',
									primary: 'hsl(41, 8%, 61%)',
								},
							})} components={
								{ IndicatorSeparator: () => null }
							} />

							{this.state.formErrors.category && <Error>{this.state.formErrors.category}</Error>}
						</div>
					}

					<div className="field">
						<label htmlFor="title" className="required">Tytuł</label>
						<input type="text" id="title" value={this.state.title} onChange={this.handleInputChange} />

						{this.state.formErrors.title && <Error>{this.state.formErrors.title}</Error>}
					</div>

					<div className="field">
						<label htmlFor="towns" className="required">Miasto</label>
						<Select id="towns" options={this.state.towns} value={this.state.selectedTown} className="select" placeholder="Wpisz miasto..." noOptionsMessage={() => 'Nie znaleziono miasta...'} menuIsOpen={this.state.isTownsDropdownVisible} onChange={this.handleTownChange} onBlur={this.hideTownsDropdown} onInputChange={this.handleTownInputChange} styles={styledSelect} theme={theme => ({
							...theme,
							borderRadius: 0,
							zIndex: 30,
							colors: {
								...theme.colors,
								primary25: 'hsl(43, 13%, 90%)',
								primary50: 'hsl(43, 13%, 90%)',
								primary: 'hsl(41, 8%, 61%)',
							},
						})} components={
							{
								DropdownIndicator: () => null,
								IndicatorSeparator: () => null,
							}
						} />

						{this.state.formErrors.town && <Error>{this.state.formErrors.town}</Error>}
					</div>

					{this.state.showRadiusOptions &&
						<div className="field">
							<label htmlFor="radius" className="required">Obszar działania</label><br />
							<Select id="radius" options={this.state.radiusOptions} value={this.state.selectedRadiusOption} className="select" placeholder="Wybierz obszar działania..." onChange={this.handleRadiusOptionChange} isSearchable={false} styles={styledSelect} theme={theme => ({
								...theme,
								borderRadius: 0,
								zIndex: 30,
								colors: {
									...theme.colors,
									primary25: 'hsl(43, 13%, 90%)',
									primary50: 'hsl(43, 13%, 90%)',
									primary: 'hsl(41, 8%, 61%)',
								},
							})} components={
								{ IndicatorSeparator: () => null }
							} />
						</div>
					}

					<div className="field">
						<label htmlFor="name" className="required">Twoje imię lub nazwa firmy</label><br />
						<input type="string" id="name" className="short" value={this.state.name} onChange={this.handleInputChange} />

						{this.state.formErrors.name && <Error>{this.state.formErrors.name}</Error>}
					</div>

					<div className="field">
						<label htmlFor="price" className="required">Cena (zł)</label>
						<i className="small">Kwota bez przecinków</i>
						<input type="number" id="price" className="short" value={this.state.price} onChange={this.handleInputChange} />

						<input type="checkbox" id="price_from" checked={this.state.price_from} onChange={this.handleCheckboxChange} /> <label htmlFor="price_from" tooltip="Zaznacz jeśli usługi mają różne ceny, a w polu podaj cenę minimalną" tooltip-wrap="true">Różny zakres cen <Hint /></label>

						{this.state.formErrors.price && <Error>{this.state.formErrors.price}</Error>}
					</div>

					<div className="field">
						<label htmlFor="phone" className="required">Numer telefonu</label><br />
						<input type="tel" id="phone" className="short" placeholder="987 654 321" value={this.state.phone} onChange={this.handleInputChange} />
						<input type="checkbox" id="allow_whatsapp" checked={this.state.allow_whatsapp} onChange={this.handleCheckboxChange} /> <label htmlFor="allow_whatsapp">Pozwól na kontakt przez WhatsAppa</label>

						{this.state.formErrors.phone && <Error>{this.state.formErrors.phone}</Error>}
					</div>

					<div className="field">
						<label htmlFor="messenger" tooltip="Podaj adres Twojego profilu lub strony na Facebooku jeśli chcesz umożliwić kontakt przez Messengera" tooltip-wrap="true">Messenger <Hint /></label>
						<br />
						<input type="text" id="messenger" className="short" placeholder="https://facebook.com/twoj-profil" value={this.state.messenger} onChange={this.handleInputChange} />
					</div>

					<div className="field">
						<label className="required">Zdjęcia</label>

						<i className="small">
							Dodaj co najmniej <b>1</b>, maksymalnie <b>15</b> zdjęć. Akceptujemy pliki <b>jpg i png</b> o minimalnym rozmiarze <b>800x600px</b>.<br />
							Pierwsze zdjęcie będzie oznaczone jako główne.
						</i>

						<input type="file" ref={this.fileSelectorRef} onChange={this.handleSelectedFiles} accept="image/jpg, image/jpeg, image/png" multiple="multiple" style={{ display: 'none' }} />

						<Gallery>
							{this.state.photosValid.length < 15 &&
								<button onClick={this.showFileUpload} type="button" className="item add">
									<div className="wrapper">
										{this.state.isUploadInProgress && <FaSpinner className="icon rotate" />}
										{!this.state.isUploadInProgress && <FaCloudUploadAlt className="icon" />}
										<p>Dodaj zdjęcie</p>
									</div>
								</button>
							}

							{this.state.photosAll.map((photo, i) => (
								<div className="item" key={i}>
									<span onClick={() => this.deleteFile(i)} className="delete" tooltip="Usuń zdjęcie">
										<FaTrash />
									</span>

									{!photo.error &&
										<>
											{/* <span className="move" tooltip="Przenieś, aby zmienić kolejność">
												<FaArrowsAlt />
											</span> */}

											<img src={'https://res.cloudinary.com/weselnicy/image/upload/c_scale,w_300,q_80/' + photo.path} alt="" />
										</>
									}

									{photo.error && <span>{photo.error}</span>}
								</div>
							))}
						</Gallery>

						{this.state.formErrors.photos && <Error>{this.state.formErrors.photos}</Error>}
					</div>

					<div className="field">
						<label htmlFor="description" className="required">Opis oferty</label>

						<EditButtons>
							<button type="button" data-style="BOLD" tooltip="Pogrubienie" onMouseDown={this.toggleInlineStyle}><FaBold /></button>
							<button type="button" data-style="ITALIC" tooltip="Kursywa" onMouseDown={this.toggleInlineStyle}><FaItalic /></button>
							<button type="button" data-style="UNDERLINE" tooltip="Podkreślenie" onMouseDown={this.toggleInlineStyle}><FaUnderline /></button>
							<button type="button" data-style="header-two" tooltip="Nagłówek 1" onMouseDown={this.toggleBlockStyle}><FaHeading /><span>1</span></button>
							<button type="button" data-style="header-three" tooltip="Nagłówek 2" onMouseDown={this.toggleBlockStyle}><FaHeading /><span>2</span></button>
							<button type="button" data-style="unordered-list-item" tooltip="Lista" onMouseDown={this.toggleBlockStyle}><FaListUl /></button>
						</EditButtons>

						<div id="description" className="textarea" onClick={this.focusEditor}>
							<Editor editorState={this.state.description} onChange={this.handleDescriptionChange} ref={element => { this.editor = element; }} />
						</div>

						{this.state.formErrors.description && <Error>{this.state.formErrors.description}</Error>}
					</div>

					<div className="field bottom">
						<div>
							<label className="required"></label> <small>&ndash; wymagane pole</small>
						</div>

						<AddOfferButton type="submit">
							{this.props.post && 'Zapisz zmiany'}
							{!this.props.post && 'Dodaję ofertę'}
							&nbsp;
							{this.state.isSubmitButtonBlocked && <FaSpinner className="rotate" />}
							{!this.state.isSubmitButtonBlocked && <FaLongArrowAltRight />}
						</AddOfferButton>
					</div>
				</StyledForm>
			</Wrapper>
		);
	};
};

export default PostForm;
